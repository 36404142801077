/*!
 * ClockPicker v0.0.7 for jQuery (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 *
 * Further modified
 * Copyright 2015 Ching Yaw Hao.
 *
 * Bootstrap v3.1.1 (http://getbootstrap.com)
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

.picker__box {
    background: $clockpicker-picker-box-background;
}

.picker__date-display {
    background-color: $clockpicker-picker-box-date-display-background-color;
}

.clockpicker-display {
    vertical-align: middle;
    display: inline-block;
    margin: auto;
    height: 85px;
    font-size: 70px;
    padding: 10px;
    padding-bottom: 0px;
    color: $clockpicker-primary-color;
}

.clockpicker-display-column {
    float: left;
}

.clockpicker-display-am-pm {
    padding-left: 5px;
    vertical-align: bottom;
    height: 85px;
}

.clockpicker-span-am-pm {
    display: inline-block;
    font-size: 23px;
    line-height: 25px;
    color: $clockpicker-primary-color;
}

.clockpicker-span-hours,
.clockpicker-span-minutes {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-transition: color 500ms;
    -moz-transition: color 500ms;
    -ms-transition: color 500ms;
    -o-transition: color 500ms;
    transition: color 500ms;
    cursor: pointer;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-moz-keyframes pulse {
    from {
        -moz-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -moz-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        -moz-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-ms-keyframes pulse {
    from {
        -ms-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -ms-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        -ms-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-o-keyframes pulse {
    from {
        -o-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -o-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        -o-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }
    50% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}

#click-am,
#click-pm {
    cursor: pointer;
}

.clockpicker-span-hours,
.clockpicker-span-minutes,
#click-am,
#click-pm {
    &.text-primary {
        -webkit-animation-name: pulse;
        animation-name: pulse;
        color: white;
    }
}

.clockpicker-moving {
    cursor: move;
}

.clockpicker-plate {
    background-color: #eeeeee;
    border-radius: 50%;
    width: 270px;
    height: 270px;
    overflow: visible;
    position: relative;
    margin: auto;
    margin-top: 20px;
    /* Disable text selection highlighting. Thanks to Hermanya */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clockpicker-canvas,
.clockpicker-dial {
    width: 270px;
    height: 270px;
    position: absolute;
    left: -1px;
    top: -1px;
}

.clockpicker-minutes {
    visibility: hidden;
}

.clockpicker-tick {
    border-radius: 50%;
    color: $clockpicker-tick-color;
    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
    position: absolute;
    cursor: pointer;
    -webkit-transition: background-color 300ms;
    -moz-transition: background-color 300ms;
    -ms-transition: background-color 300ms;
    -o-transition: background-color 300ms;
    transition: background-color 300ms;
    background-color: $clockpicker-tick-background-color;
    &.active,
    &:hover {
        background-color: $clockpicker-tick-background-color-active;
    }
}

.clockpicker-dial {
    -webkit-transition: -webkit-transform 350ms, opacity 350ms;
    -moz-transition: -moz-transform 350ms, opacity 350ms;
    -ms-transition: -ms-transform 350ms, opacity 350ms;
    -o-transition: -o-transform 350ms, opacity 350ms;
    transition: transform 350ms, opacity 350ms;
}

.clockpicker-dial-out {
    opacity: 0;
}

.clockpicker-hours.clockpicker-dial-out {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
}

.clockpicker-minutes.clockpicker-dial-out {
    -webkit-transform: scale(.8, .8);
    -moz-transform: scale(.8, .8);
    -ms-transform: scale(.8, .8);
    -o-transform: scale(.8, .8);
    transform: scale(.8, .8);
}

.clockpicker-canvas {
    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -ms-transition: opacity 300ms;
    -o-transition: opacity 300ms;
    transition: opacity 300ms;
}

.clockpicker-canvas-out {
    opacity: 0.25;
}

.clockpicker-canvas-bearing {
    stroke: none;
    fill: $clockpicker-canvas-bearing-fill;
}

.clockpicker-canvas-fg {
    stroke: none;
    fill: rgba($clockpicker-canvas-fg-fill, 0);
    &.active {
        fill: rgba($clockpicker-canvas-fg-fill, 0.5);
    }
}

.clockpicker-canvas-bg {
    stroke: none;
    fill: $clockpicker-canvas-bg-fill;
}

.clockpicker-canvas-bg-trans {
    fill: $clockpicker-canvas-bg-fill;
}

.clockpicker-canvas line {
    stroke: $clockpicker-canvas-bg-stroke;
    stroke-width: 1;
    stroke-linecap: round;
    /*shape-rendering: crispEdges;*/
}

.clockpicker-am-pm-block {
    margin-top: -10px;
    width: 100%;
    height: 50px;
}

//AM PM buttons
.clockpicker-button {
    &.am-button {
        height: 45px;
        width: 45px;
        float: left;
    }
    &.pm-button {
        height: 45px;
        width: 45px;
        float: right;
    }
}

button {
    color: $clockpicker-button-color;
}

//buttons
.btn-floating {
    &.btn-flat {
        &:focus,
        &:visited {
            background-color: transparent;
        }
        &:hover {
            box-shadow: none;
            background-color: transparent;
        }
        &.active {
            background-color: rgba($clockpicker-button-background-color, 0.25);
        }
        &:active {
            background-color: rgba($clockpicker-button-background-color, 0.5);
        }
    }
}

//footer
.picker__footer {
    .clockpicker-button {
        margin: auto;
        margin-top: 15px;
        &:focus {
            background-color: transparent;
        }
        &:active {
            background-color: rgba($clockpicker-button-background-color, 0.25);
        }
    }
}
